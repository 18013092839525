import React from "react";
import videos from "../../../data/videosData"; // Import your video data
import { Link } from "react-router-dom";

const DocumentationContent = ({ searchTerm }) => {
  // Find the video that matches the searchTerm
  const video = videos.find(
    (v) => v.title.toLowerCase() === searchTerm.toLowerCase()
  );

  if (!video) {
    return (
      <div className="documentation-content">
        <h1>Knowledge not found</h1>
        <p>We couldn't find any Knowledge matching your search.</p>
      </div>
    );
  }

  return (
    <div className="documentation-content">
      <ul className="breadcrumbs">
        <li>
          <Link to="/">SSA Knowledge Base</Link>
        </li>
        <li>{video.title}</li>
      </ul>

      <div className="text-content">
        <h1>{video.title}</h1>
        <p>{video.text}</p> {/* Display the text for the video */}
        <video
          key={video.url}
          width="100%"
          controls
          style={{ position: "relative" }}
        >
          <source src={video.url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
  );
};

export default DocumentationContent;
