import React from "react";
import { Link } from "react-router-dom"; // Import the Link component

const DocumentationLink = ({ href, imgSrc, imgAlt, title, description }) => {
  return (
    <div className="col">
      <div className="documentation-links__single">
        <Link to={href} className="anchor-overlay"></Link>
        <figure className="documentation-links__icon">
          <img
            src={imgSrc}
            alt={imgAlt}
            height="28"
            width="28"
            className="white-icon"
          />
        </figure>

        <div className="documentation-links__content">
          <h2>{title}</h2>
          <p>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default DocumentationLink;
