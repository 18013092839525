import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import videos from "../../../data/videosData";

const SearchHelper = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  const filteredVideos = videos.filter((video) =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/item/${searchTerm}`);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setShowDropdown(false);
    navigate(`/item/${suggestion}`); // Navigate to the Item route with the clicked suggestion
  };

  return (
    <section className="search-helper">
      <div className="container">
        <div className="search-helper__inner">
          <div className="search-form">
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                placeholder="What can we help you find?"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  setShowDropdown(true);
                }}
                onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
              />
              {showDropdown && searchTerm && (
                <div className="dropdown">
                  {filteredVideos.map((video, index) => (
                    <div
                      key={index}
                      className="dropdown-item"
                      onClick={() => handleSuggestionClick(video.title)}
                    >
                      {video.title}
                    </div>
                  ))}
                </div>
              )}
              <input type="submit" value="submit" />
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SearchHelper;
