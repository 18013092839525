import React from "react";
import arrow from "../../../images/arrow-right.svg";

const SidebarToggle = ({ toggleSidebar, isSidebarOpen }) => {
  return (
    <button
      type="button"
      className={`documentation-sidebar__toggle ${
        isSidebarOpen ? "absolute" : ""
      }`}
      onClick={toggleSidebar}
    >
      <img src={arrow} alt="Arrow Right/Left Icon" height="30" width="30" />
    </button>
  );
};

export default SidebarToggle;
