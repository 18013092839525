import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom"; // Import the Link and useNavigate components
import videos from "../../../data/videosData";
import logo from "../../../images/Logo.png";

const Sidebar = ({ isOpen, toggleSidebar, darkMode, toggleDarkMode }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);

  const navigate = useNavigate();

  const filteredVideos = videos.filter((video) =>
    video.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm) {
      navigate(`/item/${searchTerm}`);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchTerm(suggestion);
    setShowDropdown(false);
    navigate(`/item/${suggestion}`);
  };

  return (
    <aside className={`documentation-sidebar ${isOpen ? "is-open" : ""}`}>
      <div className="documentation-sidebar__logo">
        <Link to="/" className="logo-card">
          <img src={logo} alt="Star Services Logo" />
        </Link>
      </div>

      <div className="search-form documentation-sidebar__form">
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            placeholder="What can we help you find?"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setShowDropdown(true);
            }}
            onBlur={() => setTimeout(() => setShowDropdown(false), 200)}
          />
          {showDropdown && searchTerm && (
            <div className="dropdown">
              {filteredVideos.map((video, index) => (
                <div
                  key={index}
                  className="dropdown-item"
                  onClick={() => handleSuggestionClick(video.title)}
                >
                  {video.title}
                </div>
              ))}
            </div>
          )}
          <input type="submit" value="submit" />
        </form>
      </div>

      <div className="documentation-sidebar__menu">
        <hr />
        <ul>
          {videos.map((video, index) => (
            <li key={index} className="menu-item">
              <Link to={`/item/${video.title}`}>{video.title}</Link>
            </li>
          ))}
        </ul>
        <hr />
      </div>

      <div className="toggle-container">
        <h4 className="toggle-text">Toggle dark mode</h4>
        <label className="switch">
          <input type="checkbox" onClick={toggleDarkMode} checked={darkMode} />
          <span className="slider round"></span>
        </label>
      </div>
    </aside>
  );
};

export default Sidebar;
