import React, { useEffect } from "react";
import "../../style.min.css";
import SearchHelper from "./components/SearchHelper";
import DocumentationLinks from "./components/DocumentationLinks";
import logo from "../../images/Logo.png";
import "./home.css";
import { Link } from "react-router-dom";

const Home = ({ darkMode, toggleDarkMode }) => {
  // Check for user's theme preference in localStorage
  useEffect(() => {
    const savedMode = localStorage.getItem("darkMode");
    const isDarkMode = savedMode !== null ? savedMode === "true" : true;
    if (isDarkMode !== darkMode) {
      toggleDarkMode();
    }
  }, [darkMode, toggleDarkMode]);

  return (
    <div className={darkMode ? "dark-mode" : ""}>
      <main className="site-content">
        <div className="logo_home">
          <div className="logoholder">
            <Link to="/">
              <img src={logo} alt="Star Services Logo" />
            </Link>
          </div>
        </div>
        <SearchHelper />
        <DocumentationLinks />
        {/* Add a button to toggle dark mode */}
        <div className="toggle-container">
          <h4 className="toggle-text">Toggle dark mode</h4>
          <label className="switch">
            <input
              type="checkbox"
              onClick={toggleDarkMode}
              checked={darkMode}
            />
            <span className="slider round"></span>
          </label>
        </div>
      </main>
    </div>
  );
};

export default Home;
