import React, { useState } from "react";
import Sidebar from "./components/Sidebar";
import SidebarToggle from "./components/SidebarToggle";
import DocumentationContent from "./components/DocumentationContent";
import { useParams } from "react-router-dom";
import "./item.css";
const Item = ({ darkMode, toggleDarkMode }) => {
  const { searchTerm } = useParams();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  return (
    <main className="site-content">
      <div className="d-flex flex-wrap documentation-page">
        <SidebarToggle
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
        />
        <Sidebar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          darkMode={darkMode}
          toggleDarkMode={toggleDarkMode}
        />

        <DocumentationContent searchTerm={searchTerm} />
      </div>
    </main>
  );
};

export default Item;
