import React from "react";
import DocumentationLink from "./DocumentationLink";
import videos from "../../../data/videosData";

const DocumentationLinks = () => {
  const randomVideos = videos.sort(() => 0.5 - Math.random()).slice(0, 4);

  return (
    <section className="documentation-links">
      <div className="container">
        <div className="documentation-links__inner">
          <div className="row">
            {randomVideos.map((video, index) => (
              <DocumentationLink
                key={index}
                href={`/item/${video.title}`} // Route to the Item component with video title as a parameter
                imgSrc={video.iconhome}
                imgAlt={video.title}
                title={video.title}
                description={video.text}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocumentationLinks;
