import files from "../images/files.svg";
import assignment from "../images/assignment.svg";
import grid from "../images/grid.svg";
import wrench from "../images/wrench.svg";
import intro from "../videos/1.Introduction to SSA.mp4";
import featuresCapabilities from "../videos/2. Features and Capabilities.mp4";
import gettingStarted from "../videos/3. Getting Started.mp4";
import industriesUseCases from "../videos/4. Industries and Use Cases.mp4";
import bestPractices from "../videos/5.Best Practices.mp4";
import troubleshooting from "../videos/6.Troubleshooting.mp4";
import securityPrivacy from "../videos/7.Security & Privacy.mp4";
import supportResources from "../videos/8.Support and Resources.mp4";

const videos = [
  {
    title: "Introduction to SSA",
    text: "An introductory guide to SSA and its core concepts.",
    url: intro,
    iconhome: files,
  },
  {
    title: "Features and Capabilities",
    text: "A deep dive into the features and capabilities of SSA.",
    url: featuresCapabilities,
    iconhome: files,
  },
  {
    title: "Getting Started",
    text: "A beginner's guide to getting started with SSA.",
    url: gettingStarted,
    iconhome: assignment,
  },
  {
    title: "Industries and Use Cases",
    text: "Explore various industries and use cases where SSA can be applied.",
    url: industriesUseCases,
    iconhome: grid,
  },
  {
    title: "Best Practices",
    text: "Learn the best practices to maximize the benefits of SSA.",
    url: bestPractices,
    iconhome: wrench,
  },
  {
    title: "Troubleshooting",
    text: "Solutions and tips for common issues faced while using SSA.",
    url: troubleshooting,
    iconhome: wrench,
  },
  {
    title: "Security & Privacy",
    text: "Understand the security and privacy measures in SSA.",
    url: securityPrivacy,
    iconhome: grid,
  },
  {
    title: "Support and Resources",
    text: "Find resources and support options for SSA.",
    url: supportResources,
    iconhome: assignment,
  },
];

export default videos;
